/* Custom styles for external libraries */
html {
  scroll-behavior: smooth;
}

svg[display="none"] {
  height: 0 !important;
}
@font-face {
  font-family: "Neue Haas Grotesk Disp W01_45L";
  src: url("../fonts/Neue_Haas_Grotesk_Disp_W01_45L.woff2") format("woff2"),
    url("../fonts/Neue_Haas_Grotesk_Disp_W01_45L.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk Disp W01_65M";
  src: url("../fonts/Neue_Haas_Grotesk_Disp_W01_65M.woff2") format("woff2"),
    url("../fonts/Neue_Haas_Grotesk_Disp_W01_65M.woff") format("woff");
}
@font-face {
  font-family: "RecifeTextWeb-Light";
  src: url("../fonts/RecifeTextWeb-Light.woff2") format("woff2"), url("../fonts/RecifeTextWeb-Light.woff") format("woff");
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/* Image editor  */
.tui-image-editor-menu {
  background: #1e1e1e !important;
}

/*Due date react-datepicker*/

.react-datepicker__input-container input[readonly] {
  cursor: not-allowed !important;
}

.dueDate .react-datepicker {
  background-color: #f0f2f580 !important;
  border: none !important;
  width: 100% !important;
  font-size: 14px !important;
}

.dueDate .react-datepicker__month-container {
  background-color: #f0f2f580 !important;
  width: 100% !important;
  box-shadow: none !important;
}

.dueDate .react-datepicker__header {
  background-color: #f0f2f580 !important;
  border-bottom: none !important;
  margin-bottom: 20px !important;
}

.dueDate
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

.dueDate .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

.dueDate .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

/* Custom styles for external libraries */

/*react-datepicker*/

.react-datepicker {
  background-color: white !important;
  border: none !important;
  width: 100% !important;
  font-size: 14px !important;
  box-shadow: 0px 0px 80px rgba(49, 50, 51, 0.25) !important;
}

.react-datepicker__input-container input {
  cursor: pointer;
  font-size: 14px;
  height: 35px !important;
  width: 100% !important;
  padding-left: 16px;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.react-datepicker__navigation {
  padding-top: 15px !important;
}

.react-datepicker__input-container input:focus {
  outline: none !important;
}

.react-datepicker__month-container {
  width: 100% !important;
  border-radius: 10px;
  padding: 10px;
}

.react-datepicker__input-time-container {
  width: 100% !important;
  border-radius: 10px;
  padding: 10px;
}

.react-datepicker__time-container {
  left: 0px !important;
  box-shadow: 0px 0px 80px rgba(49, 50, 51, 0.25) !important;
  margin-top: -15px;
}

.react-datepicker__time-list {
  height: 100% !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  margin-bottom: 20px !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #462ac4 !important;
  border-radius: 100% !important;
}

.react-datepicker__year-read-view--down-arrow {
  margin-top: 4px !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  margin-bottom: 0px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 0px;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 80px;
  position: fixed;
  left: 258px;
  background: white;
  border-radius: 10px;
}

.react-datepicker--time-only {
  height: calc(265px + (1.7rem / 2));
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: hidden;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  margin-bottom: 5px;
}

.react-datepicker__time-box {
  width: 78px;
  overflow-x: initial;
  margin-top: -20px;
  text-align: center;
  height: 240px;
  border-radius: 0px;
}

.react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: initial;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px;
}

/* React Mentions */
.mentions {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(129, 129, 151, 0.4);
  border-radius: 10px;
}

.mentions--multiLine .mentions__control {
  font-size: 14px;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 7px 10px;
  padding-right: 25px;
  outline: 0;
  max-height: 100px;
  overflow: hidden;
}
.mentions--multiLine .mentions__input {
  border: 1px solid transparent;
  padding: 7px 10px;
  padding-right: 25px;
  outline: 0;
  max-height: 100px;
  overflow: auto;
}

.mentions__input:disabled {
  cursor: not-allowed;
}

.mentions__suggestions__list {
  background-color: white;
  box-shadow: 0px 0px 80px rgba(49, 50, 51, 0.25);
  border-radius: 10px;
  width: 240px;
  max-height: 130px;
  overflow: auto;
  position: fixed;
  padding-bottom: 80px;
  padding-right: 25px;
}

.mentions__suggestions__item {
  background-color: transparent;
}

.mentions__suggestions__item--focused {
  background-color: #f0f2f5;
}

.mentions__mention {
  width: 100%;
  position: relative;
  z-index: 1;
  color: #ff9a00;
  text-decoration: none;
  pointer-events: none;
  background-color: transparent;
  border-radius: 10px;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  padding-bottom: 150%;
  padding-left: 50%;
  margin-left: -60px;
  font-size: 11px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  padding-top: 100%;
  padding-left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 10px;
  color: #ff9a00;
  font-weight: 700px;
}

.tooltip-wrapper:hover .tooltiptext {
  visibility: visible;
}

.sigPad {
  width: 100%;
  height: 100%;
}
